
































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import DatesPicker from "@/shared/components/pickers/DatesPicker.vue";
import DateUtil from "@/shared/utils/DateUtil";

@Component({
  components: { DatesPicker },
})
export default class ExternalHistorySyncingDialog extends Vue {
  @Prop() value!: boolean;

  readonly maxDate = DateUtil.today();
  configurationDates: Array<string> = [];

  get isHistoryDialogVisible(): boolean {
    return this.value;
  }

  set isHistoryDialogVisible(value: boolean) {
    this.$emit("input", value);
  }

  get isHistorySyncing(): boolean {
    return this.$store.state.externalTestStore.isHistorySyncing;
  }

  @Watch("isHistoryDialogVisible")
  resetConfigurationDates() {
    this.configurationDates = [];
  }

  async syncHistory() {
    await this.$store.dispatch(
      "syncExternalTestConfigHistory",
      this.configurationDates
    );
    this.isHistoryDialogVisible = false;
  }
}
