


















































































































import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { isEqual } from "lodash";

import ExternalConfigurationEventPicker from "@/ab-tests/components/ExternalConfigurationEventPicker.vue";
import ExternalHistorySyncingDialog from "@/ab-tests/components/ExternalHistorySyncingDialog.vue";
import { AppSection, Dictionary, DictionaryType } from "@/shared/models";
import { VueForm } from "@/shared/types/ExtendedVueType";
import ValidUtil from "@/shared/utils/ValidUtil";
import { ExternalTestConfigModel } from "@/ab-tests/models/ExternalTest";

@Component({
  components: {
    ExternalHistorySyncingDialog,
    ExternalConfigurationEventPicker,
  },
})
export default class ExternalConfigurationView extends Vue {
  @Ref("form") readonly form!: VueForm;

  readonly rules = [ValidUtil.required(this.$lang("validation.required"))];
  isValid = true;
  config = new ExternalTestConfigModel(this.applicationId);
  isConfirmationDialogVisible = false;
  isHistoryDialogVisible = false;
  eventParams: Array<Dictionary> = [];
  isEventParamsDictionaryLoading = false;

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get externalRoute(): { name: string; params: { id: string } } {
    return {
      name: AppSection.EXTERNAL_TESTS,
      params: { id: this.applicationId },
    };
  }

  get eventNames(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.EVENT_NAMES].values;
  }

  get groupNameEventParams(): Array<Dictionary> {
    return this.eventParams.filter(
      ({ value }: Dictionary) => value !== this.config.abTestNameParamKey
    );
  }

  get externalTestConfig(): ExternalTestConfigModel {
    return this.$store.state.externalTestStore.externalTestConfig;
  }

  get isExternalTestConfigSaving(): boolean {
    return this.$store.state.externalTestStore.isExternalTestConfigSaving;
  }

  get isBtnDisabled(): boolean {
    return (
      !this.isValid ||
      this.isExternalTestConfigSaving ||
      isEqual(this.config, this.externalTestConfig)
    );
  }

  @Watch("applicationId", { immediate: true })
  async watchApplicationId() {
    await Promise.all([
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [DictionaryType.EVENT_NAMES],
      }),
      this.$store.dispatch("getExternalTestConfig"),
    ]);

    if (!this.externalTestConfig.eventToken) {
      return;
    }

    this.$store.dispatch("loadUsedDictionaries", {
      app: this.applicationId,
      values: {
        [DictionaryType.EVENT_NAMES]: [this.externalTestConfig.eventToken],
      },
    });
    this.loadEventParamDictionary();
  }

  @Watch("externalTestConfig", { immediate: true })
  watchExternalTestConfig() {
    if (!this.externalTestConfig) {
      return;
    }

    this.config = ExternalTestConfigModel.of(this.externalTestConfig);
  }

  created() {
    document.title = this.$lang("externalTest.configurationTitle");
  }

  async saveConfig() {
    if (!this.isConfirmationDialogVisible) {
      if (!this.form.validate()) {
        return;
      }

      if (this.externalTestConfig) {
        this.isConfirmationDialogVisible = true;
        return;
      }
    }

    await this.$store.dispatch("saveExternalTestConfig", this.config);
    this.$router.push(this.externalRoute);
  }

  onEventTokenChange(eventToken?: string) {
    this.config.abTestNameParamKey = undefined;
    this.config.abTestGroupParamKey = undefined;
    this.eventParams = [];

    if (!eventToken) {
      return;
    }

    this.loadEventParamDictionary();
  }

  onAbTestNameParamKeyChange() {
    if (this.config.abTestNameParamKey !== this.config.abTestGroupParamKey) {
      return;
    }

    this.config.abTestGroupParamKey = undefined;
  }

  async loadEventParamDictionary() {
    this.isEventParamsDictionaryLoading = true;

    try {
      const { dictionaryValues } = await this.$store.dispatch(
        "loadDictionary",
        {
          app: this.applicationId,
          type: DictionaryType.EVENT_PARAM_KEYS,
          parentValues: [this.config.eventToken],
          isForLocal: true,
          searchLimit: -1,
        }
      );

      this.eventParams = Dictionary.ofArray(dictionaryValues);
    } finally {
      this.isEventParamsDictionaryLoading = false;
    }
  }
}
