














import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";
import { Dictionary, DictionaryType } from "@/shared/models";

@Component({
  components: { PickerDictionaryNotification, CustomAutocomplete },
})
export default class ExternalConfigurationEventPicker extends mixins(
  DictionaryLoadMixin
) {
  @Prop() value?: string;

  dictionaryType = DictionaryType.EVENT_NAMES;

  get localValue(): string | undefined {
    return this.value;
  }

  set localValue(value) {
    this.$emit("input", value);
  }

  get items(): Array<Dictionary> {
    return this.dictionaryData.sort((itemA: Dictionary, itemB: Dictionary) => {
      const searchText = this.search?.toLowerCase();

      return (
        itemA.text.toLowerCase().indexOf(searchText) -
        itemB.text.toLowerCase().indexOf(searchText)
      );
    });
  }

  @Watch("localValue")
  watchName(newValue: string) {
    this.setUsedDictionaries([newValue]);
  }

  onSearch(value: string | null) {
    const searchValue =
      this.localValue &&
      this.dictionaryData.find(
        (dictionary) => dictionary.value === this.localValue
      )?.text === value
        ? null
        : value;

    this.onSearchChange(searchValue);
  }
}
